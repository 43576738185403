<template>
  <h1>Events For Good</h1>
  <div class="events">
    <event-card
      v-for="event in events"
      :key="event.id"
      :event="event"
      class=""
    ></event-card>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
import EventService from "@/services/EventService";

export default {
  name: "EventList",
  components: {
    EventCard
  },
  data() {
    return {
      events: []
    };
  },
  created() {
    EventService.getEvents()
      .then(response => (this.events = response.data))
      .catch(error => console.log(error));
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
